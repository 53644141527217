.tableButton {
    background-color: #034832;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .tableButton:hover {
      background-color: #023324;
  }
  
  .tableButtonGold {
    background-color: #CDAF5E;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .tableButtonGold:hover {
      background-color: #B89C4A;
  }

  .tableButtonGreen {
    background-color: #034832;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .tableButtonGreen:hover {
    background-color: #023324 !important;
    border: none !important;
    color: white !important;

  }

.container {
    padding: 24px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    color: #034832;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
}

.statsContainer {
    margin-bottom: 24px;
}

.statsContainer :global(.ant-card) {
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.statsContainer :global(.ant-card):hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.statsContainer :global(.ant-statistic-title) {
    color: #666;
    font-size: 14px;
    font-weight: 500;
}

.statsContainer :global(.ant-statistic-content) {
    color: #034832;
    font-weight: 600;
}

.tableContainer {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.table {
    margin-top: 16px;
}

.table :global(.ant-table-thead > tr > th) {
    background-color: #f8f8f8;
    color: #333;
    font-weight: 600;
}

.rating {
    font-weight: 600;
    color: #034832;
}

.table :global(.ant-table-tbody > tr:hover > td) {
    background-color: #f0f7f4;
}

.collapse {
    background: white;
}

.collapse :global(.ant-collapse-item) {
    margin-bottom: 16px;
    border: 1px solid #f0f0f0 !important;
    border-radius: 8px !important;
}

.collapse :global(.ant-collapse-header) {
    background-color: #fafafa;
    border-radius: 8px 8px 0 0 !important;
}

.collapse :global(.ant-collapse-content) {
    border-top: 1px solid #f0f0f0;
}

.panelHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.strategyStats {
    margin: 16px 0 24px;
    padding: 16px;
    background-color: #fafafa;
    border-radius: 8px;
}

.strategyStats :global(.ant-statistic) {
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
} 
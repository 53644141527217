.report-button {
  transition: all 0.2s ease !important;
  background-color: #cdaf5e !important;
  border-color: #cdaf5e !important;
}

/* Common hover effects for all buttons */
.report-button:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
  background-color: #dfc176 !important;
  border-color: #dfc176 !important;
}

/* Export buttons - Green theme */
.export-button {
  background-color: #003d2f !important;
  border-color: #003d2f !important;
}

.export-button:hover {
  background-color: #004d3a !important;
  border-color: #004d3a !important;
  filter: brightness(1.2) !important;
}

/* Non-export buttons - Beige theme */
.report-button:not([style*="background-color: #003d2f"]) {
  background-color: #cdaf5e !important;
  border-color: #cdaf5e !important;
}

.report-button:not([style*="background-color: #003d2f"]):hover {
  background-color: #dfc176 !important;
  border-color: #dfc176 !important;
  filter: brightness(1.1) !important;
}

.ant-tabs-tab:hover {
  color: #015544 !important;
}

.ant-tabs-tab-active {
  color: #003d2f !important;
}

.ant-tabs-ink-bar {
  background-color: #003d2f !important;
} 
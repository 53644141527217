.main {
    height: calc(100% - 80px);
    width: calc(100% - 60px);
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.navBar {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 150px;
    margin-top: 20px;
    gap: 10px
}

.tab {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #555555;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
}

.tabActive {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    background-color: #f4f4f4;
    width: 100%;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
}

.tab:hover {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #555555;;
    cursor: pointer;
    background-color: #fafafa;
}

.tabContent {
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.titleContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: black;
}

.subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #555555;
}

.lineBreak {
    width: 100%;
    height: 1px;
    background-color: #eeeef2;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sectionContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.sectionContainerTable {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.sectionContainerLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35%;
}

.sectionTitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.sectionSubtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #555555;
}

.sectionContainerRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 50%;
}

.dateJoinedText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #979797;
    margin-top: 8px;
}

.cancelButton {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #555555;
    cursor: pointer;
    background-color: #ebebeb;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
}

.submitButton {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    background-color: #004630;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
}

.sendButton {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    background-color: #004630;
    border-radius: 5px;
    width: 50px;
    transition: all 0.3s ease-in-out;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendButton:hover {
    background-color: #003d2a;
}

.cancelButton:hover {
    background-color: #eaeaea;
}

.submitButton:hover {
    background-color: #003d2a;
}

.uploadButton {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #555555;
    cursor: pointer;
    background-color: #ebebeb;
    border-radius: 5px;
    padding: 7px 15px;
    transition: all 0.3s ease-in-out;
    border: 1.5px solid #ebebeb
}

.uploadButton:hover {
    background-color: #eaeaea;
    color: #003d2a;
    border: 1.5px solid #003d2a
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px
}

.tabContentInner {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.textFieldWithButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}

.tableHeader {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.tableHeaderItem {
    width: 150px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #555555;
}

.tableRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.tableRowItem {
    /* width: 150px; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.tableRowItemButton {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    background-color: #004630;
    border-radius: 5px;
    padding: 5px 10px;
    transition: all 0.3s ease-in-out;
}

.tableRowItemButton:hover {
    background-color: #003d2a;
}


.memberAvatar {
    height: 30px;
    width: 30px;
    border-radius: 3px;
    border: 1px solid #004630;
}

.roleSelect {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #f4f4f4;
    border: 1px solid #ebebeb;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: black;
    width: fit-content;
    cursor: pointer;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px
}

.menuText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.actionButtonsContainer {
    display: flex;
    flex-direction: row;
}

.removeButton, .editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    border: 1px solid #ebebeb;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    color: black;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.removeButton:hover, .editButton:hover {
    background-color: #ebebeb;
    color: rgb(234, 90, 90);
}

.removeButtonTwo {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #555555;
    cursor: pointer;
    background-color: #ebebeb;
    border-radius: 5px;
    padding: 7px 15px;
    transition: all 0.3s ease-in-out;
    border: 1.5px solid #ebebeb
}

.removeButtonTwo:hover {
    background-color: #ebebeb;
    color: rgb(234, 90, 90);
    border: 1.5px solid #ebebeb;
}

.pfpUploadContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 100%;
    margin-top: 5px;
    gap: 20px;
}

.uploadAvatar {
    height: 45px;
    width: 45px;
    border-radius: 3px;
    border: 1px solid #004630;
}

.roleGroupHeader {
    padding: 24px 0 16px 0;
}

.roleGroupTitle {
    font-family: var(--font) !important;
    color: var(--text-primary);
    font-size: 18px !important;
    font-weight: 600 !important;
    display: flex;
    align-items: center;
}

.memberCount {
    color: var(--text-secondary);
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500 !important;
}

.roleGroupDivider {
    height: 1px;
    background-color: #f0f0f0;
    margin: 24px 0;
}

.tableRow {
    transition: background-color 0.2s ease;
}

.tableRow:hover {
    background-color: #f8f9fa;
}

.roleGroupTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.roleGroupTitleContainer:hover {
  background-color: var(--hover-color, #f5f5f5);
}

.expandIcon {
  transition: transform 0.3s ease;
  color: var(--text-secondary);
}

.expandIcon.expanded {
  transform: rotate(180deg);
}

.roleGroupHeader {
  user-select: none;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  /* border-top: 1px solid var(--border-color); */
}

.clientFilter {
  width: 200px;
}

.roleGroupTitle {
  display: flex;
  align-items: center;
  min-width: 150px;
}
.main {
    background-color: white;
    box-shadow: none;
    padding: 10px 24px;
    width: 100vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    flex-direction: row;
    flex-grow: 1;
    cursor: pointer;
}

.icon {
    background-color: #f5f5f5;
    border-radius: 8px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(0, 0, 0, 0.06);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.icon:hover {
    border-color: #034832;
    transform: translateY(-1px);
    box-shadow: 
        0 2px 8px rgba(3, 72, 50, 0.08),
        0 1px 2px rgba(3, 72, 50, 0.02);
    background-color: #fafafa;
}

.title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    flex-grow: 1;
    color: #2a3836;
    letter-spacing: -0.3px;
}

.avatar {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(0, 0, 0, 0.06);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
}

.avatar:hover {
    cursor: pointer;
    border-color: #034832;
    transform: translateY(-1px) scale(1.02);
    box-shadow: 
        0 2px 8px rgba(3, 72, 50, 0.08),
        0 1px 2px rgba(3, 72, 50, 0.02);
    background-color: #fafafa;
}

.links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-right: 24px;
    height: 40px;
}

.menuContainer {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 100%;
}

.link {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #2a3836;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 8px 14px;
    height: 100%;
    white-space: nowrap;
    border-radius: 8px;
    letter-spacing: -0.1px;
}

.link:after {
    content: '';
    position: absolute;
    bottom: 6px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #034832;
    transform: translateX(-50%);
    transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 4px;
}

.link:hover:after {
    width: calc(100% - 24px);
}

.linkText {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    position: relative;
}

.link svg {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: #2a3836;
    margin-top: 1px;
    opacity: 0.7;
}

.activeLink {
    composes: link;
    color: #034832;
    background-color: rgba(3, 72, 50, 0.04);
}

.activeLink svg {
    transform: rotate(180deg);
    color: #034832;
    opacity: 1;
}

.activeLink:after {
    width: calc(100% - 24px);
}

.dropdownMenu {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%) translateY(-8px) scale(0.98);
    background: white;
    border-radius: 12px;
    box-shadow: 
        rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
        rgba(0, 0, 0, 0.02) 0px 4px 12px -2px,
        rgba(0, 0, 0, 0.01) 0px 2px 4px -1px;
    min-width: 220px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(0, 0, 0, 0.04);
    z-index: 1000;
    padding: 8px 4px;
    transform-origin: top center;
}

.dropdownMenu::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    z-index: -1;
}

.dropdownMenuVisible {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0) scale(1);
}

.dropdownMenuItem {
    padding: 10px 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #2a3836;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    position: relative;
    margin: 0 4px;
    border-radius: 8px;
    letter-spacing: -0.1px;
    background: transparent;
}

.dropdownMenuItem.imageItem {
    justify-content: center;
    padding: 12px 16px;
    margin: 4px;
}

.dropdownMenuItem.imageItem:hover {
    padding: 12px 16px;
    transform: translateY(-1px);
    background-color: #fafafa;
}

.dropdownMenuItem.imageItem img {
    height: 30px;
    object-fit: contain;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.dropdownMenuItem.imageItem:hover img {
    transform: scale(1.05);
}

.dropdownMenuItem:hover {
    background-color: rgba(3, 72, 50, 0.04);
    color: #034832;
    padding-left: 20px;
    transform: translateX(2px);
    box-shadow: 0 1px 2px rgba(3, 72, 50, 0.02);
}

.dropdownMenuItem:active {
    background-color: rgba(3, 72, 50, 0.08);
    transform: scale(0.98);
}

.dropdownDivider {
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.03));
    margin: 6px 8px;
}

.link:hover {
    color: #034832;
    background-color: rgba(3, 72, 50, 0.04);
}

.link:hover svg {
    color: #034832;
    opacity: 1;
    transform: translateY(1px);
}

.activeLink:hover svg {
    transform: rotate(180deg) translateY(-1px);
}

.notificationButton {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    border: 1.5px solid rgba(0, 0, 0, 0.06);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: relative;
    color: #2a3836;
    margin-right: 12px;
}

.notificationButton:hover {
    border-color: #034832;
    transform: translateY(-1px) scale(1.02);
    box-shadow: 
        0 2px 8px rgba(3, 72, 50, 0.08),
        0 1px 2px rgba(3, 72, 50, 0.02);
    background-color: #fafafa;
}

.notificationBadge {
    background-color: #034832;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    right: -2px;
    box-shadow: 0 0 0 2px white, 0 0 0 4px rgba(3, 72, 50, 0.05);
}

.notificationsContainerHeader {
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 16px 20px;
    background: white;
}

.nchTitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.nchTextButton {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

.noNotificationsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    color: #858a8a;
    font-size: 14px;
    font-weight: 500;
}

.notificationsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-height: 340px;
    overflow-y: auto;
}

.notificationCard {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
}

.notificationCard:hover {
    background-color: #f5f5f5;
}

.notificationCardIndicator {
    background-color: #034832;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    min-width: 10px;
}

.ncInnerCard {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3px;
    width: 100%;
}

.clearAllButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 13px;
    padding-bottom: 13px;
    border-top: 1px solid #ebeaea;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: -7px;
}

.clearAllButton:hover {
    background-color: #f5f5f5;
    color: #FF4D4D;
}
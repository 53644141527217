.container {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.header {
  margin-bottom: 24px;
}

.title {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #2a3836 !important;
  letter-spacing: -0.3px !important;
  margin-bottom: 8px !important;
}

.subtitle {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px !important;
  color: #555555 !important;
}

.filtersContainer {
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
              rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
  padding: 20px;
  margin-bottom: 24px;
}

.filterGroup {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  flex-wrap: wrap;
}

.filterItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filterLabel {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 14px !important;
  color: #555555 !important;
  font-weight: 500 !important;
}

.tableContainer {
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
              rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
  padding: 20px;
}

.messageCell {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 40px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 1.5;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.weatherSummary {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.weatherDetail {
  margin: 4px 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
}

.weatherIcon {
  color: #034832;
  width: 24px;
  height: 24px;
}

.statusBadge {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 4px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.statusBadge.sent {
  background: rgba(82, 196, 26, 0.1);
  color: #52c41a;
}

.statusBadge.failed {
  background: rgba(245, 34, 45, 0.1);
  color: #f5222d;
}

.button {
  height: 40px;
  padding: 0 24px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 500 !important;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px !important;
  text-transform: none !important;
}

.button:hover {
  transform: translateY(-1px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.statsContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.statCard {
  background: white;
  border-radius: 8px;
  padding: 16px;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
              rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.statLabel {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 14px !important;
  color: #555555 !important;
}

.statValue {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #034832 !important;
}

.tableRow {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tableRow:hover {
  background-color: #f8f9fa;
}

/* Modal Styles */
.strategyModal {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.strategyModal :global(.ant-modal-content) {
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modalHeader {
  background: #f8f9fa;
  padding: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.modalHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.modalTitle {
  font-size: 20px;
  font-weight: 600;
  color: #2a3836;
  margin: 0;
}

.modalSubheader {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.modalDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #555555;
  font-size: 14px;
}

.modalDetail svg {
  color: #034832;
}

.modalSection {
  padding: 0 24px;
}

.modalSection:last-child {
  padding-bottom: 24px;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 600;
  color: #2a3836;
  margin-bottom: 16px;
}

.messageBox {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  white-space: pre-wrap;
  line-height: 1.6;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.weatherBox {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.weatherGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 24px;
}

.weatherGridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.weatherValue {
  font-size: 24px;
  font-weight: 600;
  color: #034832;
}

.weatherLabel {
  font-size: 14px;
  color: #555555;
}

.noWeather {
  text-align: center;
  color: #555555;
  padding: 32px;
}

.sitesList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.siteTag {
  background: rgba(3, 72, 50, 0.08) !important;
  border: 1px solid rgba(3, 72, 50, 0.1) !important;
  color: #034832 !important;
  padding: 6px 12px !important;
  border-radius: 4px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
}

.detailContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detailContent > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.additionalDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.additionalDetails > div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Update existing styles */
.warningIcon {
  color: #f5a623;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

/* Update existing hover styles to not conflict */
.hourlyItem:hover {
  box-shadow: none;
  transform: none;
}

.loadingState,
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 32px;
  color: #555555;
  text-align: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.surveyButton {
  background-color: #034832 !important;
  border-color: #034832 !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 8px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 500 !important;
  height: 36px !important;
  padding: 0 16px !important;
  transition: all 0.2s ease !important;
}

.surveyButton:hover {
  background-color: #023324 !important;
  border-color: #023324 !important;
  transform: translateY(-1px) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.surveyButton:active {
  transform: translateY(0) !important;
}

.surveyButton:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.emailsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}

.emailTag {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: white !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
}

.modalSection {
  margin-bottom: 24px;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.recipientsList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
}

.siteRecipients {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 12px;
}

.siteTag {
  background: #e6f4ff;
  color: #1677ff;
  border: none;
  font-weight: 500;
}

.contactsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-left: 8px;
}

.emailTag {
  background: #fafafa;
  border-color: #f0f0f0;
  color: #666;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
} 
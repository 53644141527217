/* MapView.css */
.vehicle-list-container,
.equipment-list-container {
  overflow-y: auto;
  height: calc(100vh - 120px);
}

.vehicle-list-container .ant-pagination,
.equipment-list-container .ant-pagination {
  text-align: center;
}

.requests-page {
  height: 100vh;
  padding: 20px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.left-panel {
  width: 100%;
  height: 40%;
}

.right-panel {
  width: 100%;
  height: 60%;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.request-list {
  overflow-y: auto;
  height: calc(100% - 150px);
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.ant-list-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 8px;
}

.ant-list-item.active-request {
  background-color: #e6f7ff;
  border-left: 4px solid #1890ff;
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-date {
  font-size: 0.9em;
  color: #000;  /* Changed from #888 to black */
  white-space: nowrap;
  margin-left: 8px;
  margin-right: 10px;
}

.request-details {
  padding: 16px;
}

.request-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row;
  }

  .left-panel {
    width: 30%;
    height: 100%;
  }

  .right-panel {
    width: 70%;
    height: 100%;
  }

  .filter-container {
    flex-direction: row;
  }

  .filter-container .ant-select,
  .filter-container .ant-input-search {
    width: 32%;
  }

  .request-list {
    height: calc(100% - 50px);
  }
}

/* Ensure modal is centered and buttons are accessible on mobile */
@media (max-width: 767px) {
  .ant-modal {
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    border-radius: 0;
  }

  .request-actions {
    flex-direction: column;
    gap: 10px;
  }

  .request-actions button {
    width: 100%;
  }

  .request-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .request-date {
    margin-left: 0;
    margin-top: 4px;
  }
}

/* Add these styles to your CSS file */
.carousel-dots {
  bottom: -20px !important;
}

.ant-carousel .slick-dots li button {
  background: #1890ff !important;
  height: 3px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
}

.ant-carousel .slick-prev {
  left: -12px;
}

.ant-carousel .slick-next {
  right: -12px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  opacity: 0.8;
}

.ant-card-small > .ant-card-body {
  padding: 12px !important;
}

/* Remove any opacity transitions or hover states that might hide the buttons */
.ant-carousel .custom-arrow-button {
  opacity: 1 !important;
  visibility: visible !important;
}

/* Optional: Add a subtle hover effect */
.ant-carousel .custom-arrow-button:hover {
  transform: translateY(-50%) scale(1.1);
  transition: transform 0.2s ease;
}
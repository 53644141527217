/* ClientRequestsPortal.css */

.client-requests-portal {
  padding: 24px;
  background-color: #ffffff; /* Changed from grey to white */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
}

.left-panel,
.right-panel {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row;
  }

  .left-panel {
    flex: 3; /* 40% */
    margin-right: 10px;
    max-height: 600px;
    overflow-y: auto;
  }

  .right-panel {
    flex: 7; /* 60% */
  }
}

/* Filters Styling */
.filters {
  margin-bottom: 20px;
}

.filters .filter-item {
  margin-right: 10px;
}

/* Active Request Highlight */
.active-request {
  background-color: #e6f7ff;
}

/* Additional Styles for List Items */
.request-header {
  display: flex;
  align-items: center;
}

.request-date {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.45);
}

/* Ensuring Map Container fills the right panel */
.right-panel .leaflet-container {
  height: 100%;
  width: 100%;
  border: 1px solid #d9d9d9;
}

.request-list .active-request {
  background-color: #e6f7ff;
}

.request-header .request-date {
  font-size: 0.9em;
}

.filters .ant-select {
  width: 100%;
}

.right-panel .leaflet-container {
  border: 1px solid #d9d9d9;
}

.request-list .ant-list-item {
  transition: all 0.3s ease;
}

.request-list .ant-list-item:hover {
  background-color: #f0f0f0;
}

.request-details .ant-statistic {
  margin-bottom: 16px;
}

.request-details .ant-card {
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.request-details .ant-card-head {
  background-color: #fafafa;
}

/* Add these styles to ensure tutorial tooltips display correctly */
.react-joyride__tooltip {
  border-radius: 8px !important;
}

.react-joyride__overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.request-list {
  position: relative;
  transform: translateZ(0); /* Forces GPU acceleration */
  will-change: transform; /* Optimizes animations */
}

/* Prevent body scrolling during tutorial */
body.joyride-active {
  overflow: hidden;
}
.container {
  padding: 24px;
  min-height: calc(100vh - 64px);
  background: #f8f8f8;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #034832;
  margin: 0;
}

.subtitle {
  color: #666;
  margin-top: 4px;
}

.filters {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.statsCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 24px;
}

.statCard {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

.statCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.statIcon {
  color: #034832;
  margin-bottom: 12px;
}

.statLabel {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #666;
  font-size: 13px;
  margin-bottom: 8px;
}

.statValue {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #034832;
}

.tabs {
  margin-bottom: 24px;
}

.tabs :global(.ant-tabs-nav) {
  margin-bottom: 16px;
}

.tabs :global(.ant-tabs-tab) {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  transition: all 0.2s;
}

.tabs :global(.ant-tabs-tab:hover) {
  color: #034832;
}

.tabs :global(.ant-tabs-tab-active) {
  font-weight: 600;
}

.tabs :global(.ant-tabs-ink-bar) {
  background: #034832;
}

/* Add styles for link hover states */
:global(.ant-btn-link:hover) {
  color: #023626 !important;
  opacity: 0.85;
}

:global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  color: #034832 !important;
  font-weight: 600;
}

.chartsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.chartCard {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-height: 360px;
  display: flex;
  flex-direction: column;
}

.chartCard:nth-child(5),
.chartCard:nth-child(6) {
  grid-column: span 1;
}

.chartTitle {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #034832;
  margin-bottom: 16px;
}

/* Enhance tooltip appearance */
:global(.recharts-tooltip-wrapper) {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

:global(.recharts-default-tooltip) {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border: 1px solid #eee !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

:global(.recharts-tooltip-label) {
  color: #034832 !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
}

/* Enhance legend appearance */
:global(.recharts-legend-item) {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

:global(.recharts-legend-item-text) {
  color: #333 !important;
}

.tableCard {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 24px;
}

.tableTitle {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #034832;
  margin-bottom: 16px;
}

.refreshButton {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.refreshButton:hover {
  background-color: #023626 !important;
}

.drawerStats, .modalStats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 24px;
}

.miniStatCard {
  text-align: center;
  padding: 16px;
}

.miniStatCard h6 {
  color: #034832;
  font-weight: 600;
  margin-top: 8px;
}

:global(.ant-drawer-content-wrapper) {
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1) !important;
}

:global(.ant-modal-content) {
  border-radius: 8px;
  overflow: hidden;
}

:global(.ant-tag) {
  border-radius: 4px;
  padding: 2px 8px;
  margin: 2px;
}

:global(.ant-table-thead > tr > th) {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  background: #f8f8f8;
}

:global(.ant-table-tbody > tr > td) {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

:global(.ant-select-selection-placeholder),
:global(.ant-select-selection-item) {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

@media (max-width: 1400px) {
  .statsCards {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .chartsContainer {
    grid-template-columns: 1fr;
  }
  
  .chartCard:nth-child(5),
  .chartCard:nth-child(6) {
    grid-column: auto;
  }
}

@media (max-width: 1200px) {
  .drawerStats, .modalStats {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .filters {
    flex-direction: column;
  }
  
  .filters > * {
    width: 100% !important;
  }
  
  .statsCards {
    grid-template-columns: 1fr;
  }
  
  .drawerStats, .modalStats {
    grid-template-columns: 1fr;
  }
} 
.roleSelect {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #f4f4f4;
    border: 1px solid #ebebeb;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: black;
    width: fit-content;
    cursor: pointer;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px
}

.tableRowContainer {
    display: flex;
    gap: 10px;
}

.tableRowItem {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px
}

.removeButton, .editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    border: 1px solid #ebebeb;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    color: black;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.removeButton:hover, .editButton:hover {
    background-color: #ebebeb;
    color: rgb(234, 90, 90);
}

.tabContentInner {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.titleContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: black;
}

.subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #555555;
}

.lineBreak {
    width: 100%;
    height: 1px;
    background-color: #eeeef2;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sectionContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.sectionContainerLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35%;
}

.sectionTitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.sectionSubtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #555555;
}

.sectionContainerRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 50%;
}

.roleGroupHeader {
    padding: 24px 0 16px 0;
}

.roleGroupTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.roleGroupTitleContainer:hover {
  background-color: var(--hover-color, #f5f5f5);
}

.roleGroupTitle {
    font-family: var(--font);
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.memberCount {
    color: var(--text-secondary);
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500 !important;
}

.expandIcon {
  transition: transform 0.3s ease;
  color: var(--text-secondary);
}

.expandIcon.expanded {
  transform: rotate(180deg);
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  /* border-top: 1px solid var(--border-color); */
}

.clientFilter {
  width: 200px;
}

.roleGroupDivider {
    height: 1px;
    background-color: #f0f0f0;
    margin: 24px 0;
}
.container {
  padding: 24px;
  min-height: calc(100vh - 64px);
  background: #f8f8f8;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #034832;
  margin: 0;
}

.subtitle {
  color: #666;
  margin-top: 4px;
}

.content {
  display: flex;
  gap: 24px;
  height: calc(100vh - 180px);
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.statsCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.statCard {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(3, 72, 50, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.statCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(3, 72, 50, 0.1);
}

.statCard::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #034832, #cdaf5e);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.statCard:hover::after {
  opacity: 1;
}

.statIcon {
  color: #034832;
  margin-bottom: 16px;
  font-size: 28px;
  transition: transform 0.3s ease;
}

.statCard:hover .statIcon {
  transform: scale(1.1);
}

.statLabel {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.statValue {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #034832;
  line-height: 1.2;
}

.chartsContainer {
  display: grid;
  grid-template-areas: 
    "storm type"
    "timeline timeline";
  gap: 24px;
}

.chartCard:nth-child(1) { grid-area: storm; }
.chartCard:nth-child(2) { grid-area: type; }
.chartCard:nth-child(3) { grid-area: timeline; }

.chartCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(3, 72, 50, 0.05);
  padding: 24px;
  height: 100%;
  transition: all 0.3s ease;
}

.chartCard:hover {
  box-shadow: 0 8px 16px rgba(3, 72, 50, 0.1);
}

.chartTitle {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #034832;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.chatContainer {
  width: 30%;
  height: calc(100vh - 180px);
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
}

.chatHeader {
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  background: #f8f9fa;
  border-radius: 12px 12px 0 0;
}

.chatHeader h6 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 600;
  color: #034832;
  margin: 0;
}

.chatHeader p {
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #666;
  margin: 4px 0 0 0;
}

.chatMessages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  scroll-behavior: smooth;
  background: #fcfcfc;
}

.chatMessages::-webkit-scrollbar {
  width: 6px;
}

.chatMessages::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.chatMessages::-webkit-scrollbar-thumb {
  background: #cdaf5e;
  border-radius: 3px;
}

.message {
  margin-bottom: 20px;
  max-width: 85%;
  animation: slideIn 0.3s ease-out;
  font-family: 'Plus Jakarta Sans', sans-serif;
  position: relative;
}

.messageHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
  font-size: 0.875rem;
  color: #666;
  font-family: 'Plus Jakarta Sans', sans-serif;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.message:hover .messageHeader {
  opacity: 1;
}

.messageContent {
  padding: 16px 20px;
  border-radius: 16px;
  line-height: 1.6;
  font-size: 0.9375rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s, box-shadow 0.2s;
}

.message:hover .messageContent {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.userMessage {
  margin-left: auto;
}

.userMessage .messageContent {
  background: linear-gradient(135deg, #034832, #045d41);
  color: white;
  border-radius: 16px 16px 4px 16px;
}

.aiMessage {
  margin-right: auto;
}

.aiMessage .messageContent {
  background: white;
  border-radius: 16px 16px 16px 4px;
  border: 1px solid rgba(205, 175, 94, 0.2);
}

.aiMessage strong {
  color: #034832 !important;
  font-weight: 600;
}

.timestamp {
  font-size: 0.75rem;
  color: #888;
  margin-left: 8px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.chatPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  text-align: center;
  padding: 24px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.quickActions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;
  justify-content: center;
}

.quickActions button {
  background: white !important;
  border: 1px solid #e0e0e0 !important;
  color: #034832 !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 0.875rem !important;
  padding: 6px 12px !important;
  height: auto !important;
  transition: all 0.2s !important;
}

.quickActions button:hover {
  background: #f5f5f5 !important;
  border-color: #034832 !important;
  transform: translateY(-1px);
}

.inputContainer {
  padding: 16px 20px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 12px;
  background: white;
  border-radius: 0 0 12px 12px;
}

.inputField {
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  padding: 12px 20px;
  font-size: 1rem;
  resize: none;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
  font-family: 'Plus Jakarta Sans', sans-serif;
  line-height: 1.5;
}

.inputField:hover {
  border-color: #cdaf5e;
}

.inputField:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(3, 72, 50, 0.1);
}

.sendButton {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #034832;
  border: none;
  cursor: pointer;
  transition: all 0.15s ease-out;
  box-shadow: 0 2px 4px rgba(3, 72, 50, 0.15);
}

.sendButton:hover {
  background: #045d41;
  transform: translateY(-1px);
  box-shadow: 0 3px 6px rgba(3, 72, 50, 0.2);
}

.sendButton:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.sendButton svg {
  width: 20px;
  height: 20px;
  color: white;
  transition: transform 0.15s ease-out;
}

.sendButton:hover svg {
  transform: translateX(1px) translateY(-1px);
  color: white;
}

.sendButton:disabled svg {
  color: #999;
}

.typingIndicator {
  display: flex;
  gap: 6px;
  padding: 12px;
  align-items: center;
}

.typingIndicator span {
  width: 6px;
  height: 6px;
  background: #cdaf5e;
  border-radius: 50%;
  animation: pulse 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

@keyframes pulse {
  0%, 100% { 
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% { 
    transform: scale(1.2);
    opacity: 1;
  }
}

.sources {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  font-size: 0.75rem;
  color: #666;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.sourceLabel {
  margin-bottom: 6px;
  display: block;
  color: #888;
}

.sourceItem {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
  color: #666;
}

.sourceItem svg {
  color: #cdaf5e;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1400px) {
  .statsCards {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .chartsContainer {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1200px) {
  .content {
    flex-direction: column;
  }
  
  .chatContainer {
    width: 100%;
    height: 500px;
  }
}

.messageActions {
  margin-left: auto;
  display: flex;
  gap: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.message:hover .messageActions {
  opacity: 1;
}

.messageActions button {
  padding: 4px !important;
  color: #666 !important;
  transition: all 0.2s !important;
}

.messageActions button:hover {
  color: #034832 !important;
  background: rgba(3, 72, 50, 0.08) !important;
}

.searchContainer {
  margin-bottom: 24px;
  position: relative;
}

.searchInput {
  width: 100%;
  padding: 12px 20px;
  padding-left: 48px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.3s ease;
  background: white;
}

.searchInput:focus {
  border-color: #034832;
  box-shadow: 0 4px 12px rgba(3, 72, 50, 0.1);
  outline: none;
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.quickActions {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.actionButton {
  padding: 8px 16px;
  border-radius: 8px;
  background: white;
  border: 2px solid #034832;
  color: #034832;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.actionButton:hover {
  background: #034832;
  color: white;
}

.loadingSkeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 1200px) {
  .content {
    flex-direction: column;
  }
  
  .chatContainer {
    width: 100%;
    height: 500px;
  }
}

.messageActions {
  margin-left: auto;
  display: flex;
  gap: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.message:hover .messageActions {
  opacity: 1;
}

.messageActions button {
  padding: 4px !important;
  color: #666 !important;
  transition: all 0.2s !important;
}

.messageActions button:hover {
  color: #034832 !important;
  background: rgba(3, 72, 50, 0.08) !important;
}

.searchContainer {
  margin-bottom: 24px;
  position: relative;
}

.searchInput {
  width: 100%;
  padding: 12px 20px;
  padding-left: 48px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.3s ease;
  background: white;
}

.searchInput:focus {
  border-color: #034832;
  box-shadow: 0 4px 12px rgba(3, 72, 50, 0.1);
  outline: none;
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
}

.quickActions {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.actionButton {
  padding: 8px 16px;
  border-radius: 8px;
  background: white;
  border: 2px solid #034832;
  color: #034832;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.actionButton:hover {
  background: #034832;
  color: white;
}

.loadingSkeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
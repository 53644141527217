:root {
  --primary-dark: #034832;
  --primary-hover: #023424;
  --secondary-highlight: #E5F2EC;
  --text-primary: rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.54);
  --error: #FF4D4E;
  --error-bg: #FDEAEA;
  --success: #034832;
  --success-bg: #E5F2EC;
  --neutral: #f0f0f0;
  --border-color: rgb(228, 227, 227);
  --card-shadow: 0 2px 4px rgba(0,0,0,0.05);
  --card-hover-shadow: 0 8px 24px rgba(0,72,50,0.1);
}

.main {
    height: 100%;
    width: calc(100% - 60px);
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}

.topRow {
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    gap: 24px;
}

.left {
    width: 35%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-size: clamp(2rem, 5vw, 3rem);
    font-weight: 700;
    line-height: 1.1;
    color: var(--text-primary);
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 0.5rem;
}

.subtitle {
    font-size: clamp(1.25rem, 2.5vw, 1.5rem);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 500;
    color: var(--text-secondary);
    margin-top: 0.25rem;
}

.right {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row;
    width: 62%;
    gap: 16px;
    flex-wrap: wrap;
}

.metricCard {
    height: auto;
    min-height: 140px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid rgb(228, 227, 227);
    flex-direction: column;
    align-self: flex-start;
    overflow: hidden;
    min-width: 0;
    width: calc(33% - 10px);
}

.metricCard:hover {
    box-shadow: var(--card-hover-shadow);
}

.metricCardTitle {
    font-size: 14px;
    font-weight: 400;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-bottom: 12px;
    width: 100%;
}

.metricCardValueContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    flex: 1;
    overflow: hidden;
    min-width: 0;
}

.metricCardValue {
    font-size: 20px;
    font-weight: 500;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
    word-wrap: break-word;
    width: 100%;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.metricCardSubtitle {
    font-size: 12px;
    font-weight: 400;
    color: gray;
    font-family: 'Plus Jakarta Sans', sans-serif;
    width: 100%;
    word-wrap: break-word;
}

.actionsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    width: 100%;
    padding-bottom: 40px;
}

.actionCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: white;
    color: var(--text-secondary);
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    gap: 5px;
    height: 80px;
    border: 1px solid var(--border-color);
    box-shadow: var(--card-shadow);
    will-change: transform, box-shadow;
}

.actionCard:hover {
    background-color: var(--secondary-highlight);
    color: var(--primary-dark);
    box-shadow: var(--card-hover-shadow);
    transform: translateY(-2px);
}

.actionCardActive {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: var(--primary-dark);
    color: white;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    gap: 5px;
    height: 80px;
    box-shadow: var(--card-hover-shadow);
}

.actionCardTopRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actionCardTopRowLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: #034832
}

.actionCardTopRowLeftActive {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: white
}

.actionCardTitle {
    font-size: 18px;
    font-weight: 500;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px
}

.actionCardTitleActive {
    font-size: 18px;
    font-weight: 500;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px
}

.actionCardTopRowRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionCardBody {
    font-size: 14px;
    font-weight: 400;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.actionCardBodyActive {
    font-size: 14px;
    font-weight: 400;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.actionCardExpanded {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.actionCardExpandedLessItems {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 7px;
}

.actionCardExpandedItem {
    width: 100%;
    height: calc(33% - 5px);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #afafaf;
    border: 1px solid rgb(228, 227, 227)
}

.actionCardExpandedItem:hover {
    background-color: #f1efef;
    color: #034832;
}

.actionCardExpandedItemLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin-left: 20px;
    color: #034832;
}

.actionCardExpandedItemRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}

.actionCardExpandedTitle {
    font-size: 14px;
    font-weight: 500;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

@media (max-width: 1200px) {
    .topRow {
        flex-direction: column;
    }
    
    .left {
        width: 100%;
        margin-bottom: 24px;
    }
    
    .right {
        width: 100%;
    }

    .metricCard {
        width: calc(50% - 8px);
    }
}

@media (max-width: 768px) {
    .main {
        padding: 15px;
        width: calc(100% - 30px);
        overflow-x: hidden;
    }

    .topRow {
        height: auto;
        flex-direction: column;
        gap: 20px;
    }

    .left {
        width: 100%;
        padding-bottom: 20px;
    }

    .right {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 15px;
        height: auto;
    }

    .metricCard {
        width: 100%;
        margin-bottom: 16px;
    }

    .title {
        font-size: clamp(28px, 5vw, 36px);
        line-height: 1.2;
    }

    .subtitle {
        font-size: clamp(16px, 3vw, 18px);
    }

    .actionsContainer {
        margin-top: 20px;
        padding-bottom: 30px;
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .actionCard, .actionCardActive {
        height: auto;
        min-height: 80px;
        padding: 15px;
    }

    .actionCardExpanded {
        gap: 12px;
    }

    .actionCardExpandedItem {
        height: 60px;
        padding: 10px 0;
    }

    .actionCardExpandedTitle {
        font-size: 16px;
    }

    .actionCardTitle, .actionCardTitleActive {
        font-size: 16px;
    }

    .actionCardBody, .actionCardBodyActive {
        font-size: 14px;
        line-height: 1.4;
    }

    .metricCardTitle {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .metricCardValue {
        font-size: 22px;
    }

    .metricCardSubtitle {
        font-size: 12px;
    }

    .messageStatusContainer {
        gap: 6px;
    }

    .messageStatusRow {
        flex-direction: column;
    }

    .messageStatusItem {
        padding: 4px 8px;
    }

    .messageStatusLabel {
        font-size: 12px;
    }

    .messageStatusCount {
        font-size: 11px;
        padding: 2px 4px;
    }

    .weatherModule {
        padding: 16px;
        margin-bottom: 16px;
    }

    .currentConditions {
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }

    .mainWeatherInfo {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
    }

    .windInfo {
        padding-left: 0;
        padding-top: 16px;
        border-left: none;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
    }

    .tempNumber {
        font-size: 36px;
    }

    .tempUnit {
        font-size: 20px;
    }

    .conditions {
        font-size: 20px !important;
    }

    .eventCard {
        flex: 0 0 260px;
    }
}

@media (max-width: 480px) {
    .main {
        padding: 12px;
        width: calc(100% - 24px);
    }

    .title {
        font-size: clamp(24px, 4vw, 28px);
    }

    .subtitle {
        font-size: clamp(14px, 2.5vw, 16px);
    }

    .metricCard {
        min-height: 140px;
        padding: 14px;
    }

    .metricCardTitle {
        font-size: 13px;
        margin-bottom: 8px;
    }

    .metricCardValue {
        font-size: 20px;
    }

    .metricCardSubtitle {
        font-size: 11px;
    }

    .actionCard, .actionCardActive {
        padding: 12px;
        min-height: 70px;
    }

    .actionCardExpandedItem {
        height: 50px;
    }

    .actionCardExpandedTitle {
        font-size: 14px;
    }

    .actionCardTitle, .actionCardTitleActive {
        font-size: 15px;
    }

    .actionCardBody, .actionCardBodyActive {
        font-size: 13px;
    }

    .actionCardExpandedItemLeft {
        margin-left: 15px;
    }

    .actionCardExpandedItemRight {
        margin-right: 15px;
    }

    .messageStatusRow {
        flex-direction: column;
    }

    .messageStatusItem {
        padding: 3px 6px;
    }

    .messageStatusLabel {
        font-size: 11px;
    }

    .messageStatusCount {
        font-size: 10px;
        min-width: 12px;
        padding: 1px 4px;
    }
}

@media (hover: none) {
    .actionCard, .actionCardActive,
    .actionCardExpandedItem {
        -webkit-tap-highlight-color: transparent;
    }

    .actionCardExpandedItem:active {
        background-color: #f1efef;
    }

    .actionCard:active {
        background-color: #f1efef;
    }
}

/* message status counter styles */
.messageStatusContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
}

.messageStatusRow {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    min-width: 0;
}

.messageStatusItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    min-width: 0;
}

.messageStatusLabel {
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 8px;
}

.messageStatusCount {
    background-color: #034832;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 600;
    min-width: 16px;
    text-align: center;
    flex-shrink: 0;
}

/* Status-specific styles */
.messageStatusItem[data-status="unread"] {
    background-color: var(--neutral);
}

.messageStatusItem[data-status="confirmed"] {
    background-color: var(--success-bg);
}
.messageStatusItem[data-status="confirmed"] .messageStatusLabel {
    color: var(--success);
}
.messageStatusItem[data-status="confirmed"] .messageStatusCount {
    background-color: var(--success);
    color: white;
}

.messageStatusItem[data-status="denied"] {
    background-color: var(--error-bg);
}
.messageStatusItem[data-status="denied"] .messageStatusLabel {
    color: var(--error);
}
.messageStatusItem[data-status="denied"] .messageStatusCount {
    background-color: var(--error);
    color: white;
}

/* tablet-specific optimizations */
@media (min-width: 768px) and (max-width: 1024px) {
    .main {
        padding: 20px 24px;
        width: calc(100% - 48px);
    }

    .topRow {
        height: auto;
        min-height: 180px;
        gap: 24px;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .left {
        width: 35%;
        padding-bottom: 0;
    }

    .right {
        width: 65%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        align-items: stretch;
        justify-content: flex-start;
    }

    .metricCard {
        width: calc(50% - 8px);
        min-height: 140px;
        height: auto;
        margin-bottom: 0;
        padding: 16px;
    }

    .metricCardTitle {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .metricCardValue {
        font-size: 22px;
    }

    .metricCardSubtitle {
        font-size: 12px;
        margin-top: auto;
        padding-top: 8px;
    }

    .messageStatusContainer {
        margin-top: 4px;
    }

    .messageStatusRow {
        flex-direction: column;
        gap: 6px;
    }

    .messageStatusItem {
        padding: 6px 10px;
    }

    .messageStatusLabel {
        font-size: 13px;
    }

    .messageStatusCount {
        font-size: 12px;
        min-width: 18px;
        padding: 2px 6px;
    }

    .actionsContainer {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        padding: 0;
        margin-top: 0;
    }

    .actionCard, .actionCardActive {
        min-height: 90px;
        padding: 16px;
    }

    .actionCardTitle, .actionCardTitleActive {
        font-size: 16px;
    }

    .actionCardBody, .actionCardBodyActive {
        font-size: 14px;
        line-height: 1.4;
    }

    .actionCardExpanded {
        gap: 12px;
    }

    .actionCardExpandedItem {
        height: 64px;
        padding: 8px 0;
    }

    .actionCardExpandedTitle {
        font-size: 15px;
    }
}

/* iPad Pro specific optimizations */
@media (min-width: 1024px) and (max-width: 1366px) {
    .main {
        padding: 24px 32px;
        width: calc(100% - 64px);
    }

    .topRow {
        height: auto;
        min-height: 200px;
        margin-bottom: 32px;
    }

    .left {
        width: 40%;
    }

    .right {
        width: 60%;
        gap: 20px;
    }

    .metricCard {
        width: calc(33.33% - 14px);
        min-height: 160px;
        padding: 20px;
    }

    .metricCardTitle {
        font-size: 15px;
        margin-bottom: 14px;
    }

    .metricCardValue {
        font-size: 24px;
    }

    .messageStatusContainer {
        margin-top: 6px;
    }

    .messageStatusRow {
        gap: 8px;
    }

    .messageStatusItem {
        padding: 7px 12px;
    }

    .messageStatusLabel {
        font-size: 14px;
    }

    .messageStatusCount {
        font-size: 13px;
        min-width: 20px;
        padding: 2px 8px;
    }

    .actionsContainer {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-top: 0;
    }

    .actionCard, .actionCardActive {
        min-height: 100px;
        padding: 18px;
    }
}

/* Weather Module Styles */
.weatherModule {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    padding: 12px;
    margin: 20px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.weatherModuleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.regionInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.regionInfo h6 {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    color: var(--text-primary);
    font-weight: 600;
}

.regionName {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    color: var(--primary-dark);
    font-size: 12px !important;
    font-weight: 500;
}

.weatherOverview {
    display: flex;
    gap: 12px;
    margin-bottom: 12px;
}

.currentConditions {
    flex: 1;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    border-radius: 6px;
}

.mainWeatherInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.temperatureDisplay {
    display: flex;
    align-items: flex-start;
}

.tempNumber {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    color: var(--text-primary);
    letter-spacing: -0.3px;
}

.tempUnit {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    margin-top: 1px;
    color: var(--text-secondary);
    font-weight: 500;
}

.weatherDetails {
    flex: 1;
}

.conditions {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    margin-bottom: 1px !important;
    color: var(--text-primary) !important;
    font-weight: 500 !important;
    letter-spacing: -0.2px;
}

.precipitation {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
}

.windInfo {
    margin-top: 6px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.windSpeed {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
}

.hourlyForecast {
    display: flex;
    gap: 10px;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    border-radius: 6px;
    align-items: center;
}

.hourlyItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    min-width: 40px;
}

.hourTime {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    color: var(--text-secondary);
    font-size: 11px !important;
    font-weight: 500;
}

.hourTemp {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    color: var(--primary-dark);
    letter-spacing: -0.2px;
}

.hourlyIcon {
    color: var(--text-secondary);
    margin: 1px 0;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hourlyPrecip {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    color: var(--text-secondary);
    font-size: 10px !important;
    font-weight: 500;
}

.upcomingEvents {
    margin-top: 12px;
}

.upcomingTitle {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    margin-bottom: 8px !important;
    color: var(--text-primary) !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    letter-spacing: -0.2px;
}

.eventsScroller {
    overflow-x: auto;
    padding-bottom: 2px;
}

.eventsList {
    display: flex;
    gap: 8px;
}

.eventCard {
    background: rgba(255, 255, 255, 0.5);
    padding: 8px;
    border-radius: 6px;
    min-width: 140px;
    transition: transform 0.2s, box-shadow 0.2s;
}

.eventCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.eventHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.eventType {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--primary-dark);
}

.eventType h6 {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    margin: 0 !important;
    color: var(--text-primary) !important;
    letter-spacing: -0.2px;
}

.eventDuration {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    background: rgba(3, 72, 50, 0.08);
    color: var(--primary-dark);
    padding: 1px 4px;
    border-radius: 3px;
    font-size: 10px !important;
    font-weight: 500;
}

.eventDetails {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.eventTime, .accumulation {
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--text-secondary);
    font-size: 11px;
    font-weight: 400;
}

.weatherLoading, .emptyWeather {
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    padding: 16px;
    color: var(--text-secondary);
    font-weight: 500;
}

.noEvents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    color: var(--text-secondary);
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .weatherModule {
        padding: 16px;
        margin-bottom: 16px;
        border-radius: 12px;
    }

    .currentConditions {
        padding: 12px;
    }

    .tempNumber {
        font-size: 32px;
    }

    .tempUnit {
        font-size: 16px;
    }

    .conditions {
        font-size: 16px !important;
    }

    .eventCard {
        flex: 0 0 220px;
        padding: 10px;
    }
}
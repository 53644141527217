@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    flex-direction: row;
}

.left {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
}

.formContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    gap: 30px;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-left: 30px;
    width: 200px;
}

.title {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 36px;
    font-weight: 500;
}

.subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #555555;
}

.right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(87, 87, 87);
}

.rightImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.4;
}

.blackOverlay {
    background-color: black;
    height: 100%;
    width: 100%;
}

.textContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    width: 100%;
}

.forgotPassword {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #555555;
    align-self: flex-end;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.forgotPassword:hover {
    color: #004630;
}

.submitButton {
    width: 100%;
    height: 45px;
    background-color: #004630;
    color: #fff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.submitButton:hover {
    background-color: #00341f;
}

.copyright {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
}

.image {
    border: none;
    width: 100%;
    border: 1px solid red;
    height: 100%;
    align-self: flex-end;
}

@media screen and (max-width: 768px) {

    .left {
        width: 100%;
        height: 100vh;
    }

    .right {
        width: 0%;
        height: 0%;
    }

    .formContainer {
        width: 90%;
    }
}
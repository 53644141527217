.container {
    padding: 48px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.title {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #2a3836 !important;
    letter-spacing: -0.3px !important;
    margin-bottom: 8px !important;
}

.subtitle {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 16px !important;
    color: #555555 !important;
}

.content {
    display: flex;
    gap: 24px;
    height: calc(100vh - 150px);
}

.leftPanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 400px;
    max-width: 600px;
}

.rightPanel {
    flex: 1;
    min-width: 400px;
}

/* Weather Section */
.weatherSection {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
}

.sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.sectionHeader h6 {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #2a3836 !important;
}

.weatherContent {
    min-height: 200px;
}

.currentWeather {
    padding: 16px;
    border-radius: 8px;
    background: #f8f9fa;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.04);
}

.mainWeather {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.temperature {
    margin-right: 24px;
}

.tempValue {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 48px !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    color: #034832 !important;
}

.tempUnit {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 24px !important;
    color: #555555 !important;
}

.conditions {
    flex: 1;
}

.conditions h6 {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #2a3836 !important;
    margin-bottom: 4px !important;
}

.conditions p {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    color: #555555 !important;
}

.weatherDetails {
    display: flex;
    gap: 24px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.detailItem {
    flex: 1;
}

.detailItem p {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    margin: 0 !important;
}

.detailItem p:first-child {
    font-size: 13px !important;
    color: #555555 !important;
    margin-bottom: 4px !important;
}

.detailItem p:last-child {
    font-size: 15px !important;
    color: #2a3836 !important;
    font-weight: 500 !important;
}

.hourlyForecast {
    margin-top: 24px;
}

.forecastTitle {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #555555 !important;
    margin-bottom: 16px !important;
}

.hourlyGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    margin-top: 8px;
}

.hourlyItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 8px;
    background: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    transition: transform 0.2s ease;
    min-width: 120px !important;
}

.hourlyItem:hover {
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px;
}

.hourTime {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #555555 !important;
    margin-bottom: 8px !important;
}

.hourTemp {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #034832 !important;
    margin: 4px 0 !important;
}

.hourlyCondition {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 13px !important;
    color: #555555 !important;
    text-align: center !important;
    margin: 4px 0 !important;
    min-height: 32px !important;
}

.precipInfo {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 12px !important;
    color: #034832 !important;
    background: rgba(3, 72, 50, 0.08) !important;
    padding: 4px 8px !important;
    border-radius: 4px !important;
    margin-top: 4px !important;
    text-align: center !important;
    width: 100% !important;
    white-space: normal !important;
    line-height: 1.3 !important;
}

.weatherLoading, .errorState {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 24px;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.04);
}

.weatherLoading {
    color: #034832;
}

.errorState {
    color: #ff4d4f;
}

.loadingIcon {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Message Section */
.messageSection {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
    margin-top: 24px;
}

.messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.messageHeader h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.generateButton {
    background-color: #034832 !important;
    border-color: #034832 !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: 500 !important;
    height: 36px !important;
    padding: 0 16px !important;
    transition: all 0.2s ease !important;
}

.generateButton:hover {
    background-color: #023324 !important;
    border-color: #023324 !important;
    transform: translateY(-1px) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.generateButton:active {
    transform: translateY(0) !important;
}

.generateButton:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
}

.messageInput {
    width: 100%;
    border-radius: 8px;
    resize: vertical;
    min-height: 120px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    line-height: 1.6;
    padding: 12px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s ease;
}

.messageInput:hover {
    border-color: #034832;
}

.messageInput:focus {
    border-color: #034832;
    box-shadow: 0 0 0 2px rgba(3, 72, 50, 0.1);
    outline: none;
}

/* Selected Sites Section */
.selectedSitesSection {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
    margin-bottom: 24px;
}

.headerLeft {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.selectedSites {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 40px;
    padding: 8px;
    background: #f8f9fa;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-top: 16px;
}

.siteTag {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 13px !important;
    background: rgba(3, 72, 50, 0.08) !important;
    color: #034832 !important;
    border: 1px solid rgba(3, 72, 50, 0.1) !important;
    border-radius: 4px !important;
    padding: 4px 8px !important;
    margin: 4px !important;
}

.siteTag :global(.anticon) {
    color: #034832 !important;
    font-size: 12px !important;
}

.noSites {
    width: 100%;
    text-align: center;
    padding: 12px;
    color: #999;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.clearButton {
    color: #666 !important;
    text-transform: none !important;
}

.clearButton:hover {
    background-color: rgba(255, 77, 79, 0.1) !important;
    color: #ff4d4f !important;
}

/* Action Section */
.actionSection {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
}

.messageActions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.sendButton {
    min-width: 200px !important;
    background-color: #034832 !important;
    color: white !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 8px 24px !important;
    height: auto !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 15px !important;
    border: none !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12) !important;
}

.sendButton:hover {
    background-color: #023324 !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2) !important;
}

.sendButton:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(255, 255, 255, 0.7) !important;
    box-shadow: none !important;
}

/* Sites Table Section */
.sitesSection {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
    height: 100%;
}

.sitesTable {
    margin-top: 16px;
}

.sitesTable :global(.ant-table) {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.sitesTable :global(.ant-table-thead > tr > th) {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: 600 !important;
    background: #f8f9fa !important;
    color: #2a3836 !important;
    padding: 12px 16px !important;
}

.sitesTable :global(.ant-table-tbody > tr > td) {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    color: #2a3836 !important;
    padding: 12px 16px !important;
}

.sitesTable :global(.ant-btn) {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    background: rgba(3, 72, 50, 0.08) !important;
    border: 1px solid rgba(3, 72, 50, 0.1) !important;
    color: #034832 !important;
}

.sitesTable :global(.ant-btn:hover) {
    background: rgba(3, 72, 50, 0.12) !important;
}

.sitesTable :global(.ant-btn:disabled) {
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.actionButton {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-transform: none !important;
    font-size: 13px !important;
    background-color: rgba(3, 72, 50, 0.08) !important;
    color: #034832 !important;
    border: 1px solid rgba(3, 72, 50, 0.1) !important;
    border-radius: 4px !important;
    padding: 4px 12px !important;
    min-width: unset !important;
    height: 28px !important;
    line-height: 1 !important;
}

.actionButton:hover {
    background-color: rgba(3, 72, 50, 0.12) !important;
}

.actionButton:disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.timeDisplay {
    margin-bottom: 12px;
    color: #666;
}

.columnHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.siteSearch {
    width: 240px !important;
}

.windDirection {
    display: flex;
    align-items: center;
    gap: 4px;
}

.warningIcon {
    color: #f5a623;
    margin-left: 8px;
    animation: pulse 2s infinite;
}

.headerLeft {
    display: flex;
    flex-direction: column;
}

.clearButton {
    color: #666 !important;
    border: 1px solid #ddd !important;
    background: white !important;
    font-size: 12px !important;
    padding: 4px 12px !important;
}

.clearButton:hover {
    color: #d32f2f !important;
    border-color: #d32f2f !important;
    background: rgba(211, 47, 47, 0.04) !important;
}

.messageGuideline {
    margin-top: 14px !important;
    color: #666 !important;
    font-size: 13px !important;
}

.confirmDetails {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
}

.confirmSites {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 12px;
    padding: 12px;
    background: #f8f9fa;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.04);
}

.confirmMessage {
    margin-top: 8px !important;
    padding: 12px;
    background: #f8f9fa;
    border-radius: 4px;
    white-space: pre-wrap;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.clickable {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.clickable:hover {
    transform: translateY(-2px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px -5px;
}

.weatherModalContent {
    padding: 20px;
}

.weatherModalMain {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 24px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
}

.weatherModalTemp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.weatherModalTemp .tempValue {
    font-size: 48px;
    font-weight: 500;
    line-height: 1;
    color: #034832;
}

.weatherModalTemp .tempUnit {
    font-size: 24px;
    color: #555555;
    margin-left: 4px;
}

.weatherModalConditions {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
}

.weatherModalDetails {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.weatherModalDetail {
    padding: 16px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.detailHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
}

.detailContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.detailContent > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.additionalDetails {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.additionalDetails > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* Update existing styles */
.warningIcon {
    color: #f5a623;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

/* Update existing hover styles to not conflict */
.hourlyItem:hover {
    box-shadow: none;
    transform: none;
}

/* Threats Section */
.threatsSection {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
    margin-bottom: 24px;
}

.threatsContent {
    margin-top: 16px;
}

.threatsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
}

.threatCard {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.threatHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.threatType {
    font-weight: 600;
    color: #034832;
    font-size: 16px;
}

.threatDuration {
    background: rgba(3, 72, 50, 0.08);
    color: #034832;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
}

.threatDetails {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.threatTime {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.threatTimeRange {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 13px;
    color: #555555;
}

.threatLocation {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 13px;
    color: #555555;
}

.threatIcon {
    color: #034832;
    flex-shrink: 0;
}

.loadingState,
.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 32px;
    color: #555555;
    text-align: center;
}

.loadingIcon {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.headerButtons {
    display: flex;
    gap: 8px;
}

.selectButton {
    color: #034832 !important;
    text-transform: none !important;
    background-color: rgba(3, 72, 50, 0.08) !important;
    border: 1px solid rgba(3, 72, 50, 0.1) !important;
}

.selectButton:hover {
    background-color: rgba(3, 72, 50, 0.12) !important;
}

.selectButton:disabled {
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

.headerControls {
    display: flex;
    gap: 12px;
    align-items: center;
}

.siteSearch {
    width: 250px;
}

/* Confirmation Modal Styles */
.confirmModal {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.confirmModal :global(.ant-modal-content) {
  padding: 24px !important;
  border-radius: 8px !important;
}

.modalConfirmHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.modalConfirmHeader h6 {
  margin: 0 !important;
  font-size: 18px !important;
}

.confirmMessage {
  background: #fff7e6;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 24px;
  color: #d48806;
}

.confirmSection {
  margin-bottom: 24px;
}

.confirmSection strong {
  display: block;
  margin-bottom: 8px;
}

.confirmValue {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 12px;
}

.confirmSites {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.confirmSiteDetails {
  background: white;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 12px;
}

.confirmSiteTag {
  color: #034832;
  font-size: 14px;
  margin-bottom: 8px;
}

.confirmContact {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: #666;
}

.confirmEmail {
  margin-left: auto;
  color: #034832;
  font-family: monospace;
}

.confirmMessageBox {
  background: #f8f9fa;
  border-radius: 4px;
  padding: 12px;
}

.confirmModal :global(.ant-modal-footer) {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
}

.confirmModal :global(.ant-btn-primary) {
  background: #034832;
  border-color: #034832;
}

.messageSection .sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.messageSection .headerLeft {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.generateButton {
  color: #034832 !important;
  text-transform: none !important;
  background-color: rgba(3, 72, 50, 0.08) !important;
  border: 1px solid rgba(3, 72, 50, 0.1) !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  height: 32px !important;
  padding: 0 12px !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 6px !important;
  transition: all 0.2s ease !important;
}

.generateButton:hover {
  background-color: rgba(3, 72, 50, 0.12) !important;
  transform: translateY(-1px) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.generateButton:active {
  transform: translateY(0) !important;
}

.generateButton:disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed !important;
  transform: none !important;
  box-shadow: none !important;
}

.generateButton :global(.anticon) {
  font-size: 16px !important;
}

/* Threat Modal Styles */
.threatModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 24px;
}

.threatModalType {
  display: flex;
  align-items: center;
  gap: 12px;
}

.threatModalType .threatIcon {
  color: #f5a623;
  animation: pulse 2s infinite;
}

.threatModalDuration {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: rgba(3, 72, 50, 0.08);
  border: 1px solid rgba(3, 72, 50, 0.1);
  border-radius: 6px;
  color: #034832;
}

.threatModalDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.threatModalSection {
  padding: 20px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px;
}

.threatModalSectionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #034832;
  margin-bottom: 16px;
}

.threatModalSectionContent {
  padding: 12px;
  background: #f8f9fa;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.threatTimeDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.threatTimeDetail:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.threatAccumulation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.threatAccumulation:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.threatModalFooter {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  text-align: center;
}

/* Update existing threat card styles to indicate clickability */
.threatCard {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.threatCard:hover {
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px -5px,
              rgba(0, 0, 0, 0.04) 0px 6px 10px -5px;
}

.threatCard:active {
  transform: translateY(0);
}

/* Add a subtle hover effect to the threat type */
.threatType {
  transition: color 0.2s ease;
}

.threatCard:hover .threatType {
  color: #023324;
}

/* Add hover effect to the duration badge */
.threatDuration {
  transition: all 0.2s ease;
}

.threatCard:hover .threatDuration {
  background: rgba(3, 72, 50, 0.12);
}

.confirmSiteDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.confirmContacts {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 8px;
  margin-top: 4px;
}

.confirmContact {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: #666;
  padding: 2px 8px;
}

.confirmEmail {
  color: #034832;
  font-family: monospace;
  font-size: 11px;
}

.confirmSiteDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.confirmSiteTag {
  background: rgba(3, 72, 50, 0.08) !important;
  border: 1px solid rgba(3, 72, 50, 0.1) !important;
  color: #034832 !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
}

.confirmContacts {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;
}

.confirmContact {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: #555;
}

.confirmEmail {
    color: #034832;
    font-family: monospace;
    margin-left: auto;
} 

.generateButton.loading {
    position: relative;
    pointer-events: none;
    opacity: 0.7;
}

.generateButton.loading svg {
    animation: spin 1s linear infinite;
}

.generateButton.loading:hover {
    transform: none !important;
    box-shadow: none !important;
}

/* Events Section */
.eventsSection {
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
              rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
  padding: 20px;
  margin-bottom: 24px;
}

.eventsContent {
  margin-top: 16px;
}

.threatAlert {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 8px;
  padding: 16px;
}

.threatDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.threatDetail:last-child {
  border-bottom: none;
}

.threatModalSection {
  background: #fafafa;
  border-radius: 8px;
  padding: 16px;
}

.threatModalSectionHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.threatModalSectionContent {
  background: white;
  border-radius: 6px;
  padding: 12px;
}

.threatTimeDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.threatTimeDetail:last-child {
  border-bottom: none;
}

/* Region Modal Styles */
.regionModalContent {
  padding: 12px;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.regionData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

/* Specific styles for bulk page modal */
.regionModal .regionData {
  grid-template-columns: 45% 55%;
}

.regionModal .modalLeftColumn,
.regionModal .modalRightColumn {
  min-width: 0;
}

.modalLeftColumn,
.modalRightColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalCard {
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 8px 32px -4px,
              rgba(0, 0, 0, 0.02) 0px 4px 8px -2px !important;
}

.modalCard :global(.ant-card-head-title),
.modalCard h6 {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #2a3836 !important;
}

.cardHeader {
  padding: 12px 16px !important;
  margin-bottom: 8px !important;
}

.cardHeader h6 {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.cardHeader p {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  color: #555555 !important;
}

.selectedSites {
  padding: 8px;
  max-height: 60px;
  overflow-y: auto;
}

.siteTag {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 12px !important;
}

.sitesTable {
  margin-top: 8px;
}

.sitesTable :global(.ant-table-thead > tr > th) {
  padding: 8px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.sitesTable :global(.ant-table-tbody > tr > td) {
  padding: 6px 8px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
}

.messageInput {
  margin: 0 16px 16px;
  resize: none;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
}

/* Weather styles in modal */
.weatherContent {
  padding: 12px;
}

.currentWeather {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.mainWeather {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.temperature {
  margin-right: 16px;
}

.tempValue {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 1 !important;
  color: #034832 !important;
}

.tempUnit {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px !important;
  color: #555555 !important;
}

.conditions {
  flex: 1;
}

.conditions h6 {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
  color: #2a3836 !important;
}

.conditions p {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  color: #555555 !important;
  margin: 0 !important;
}

.weatherDetails {
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.detailItem p {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  margin: 0 !important;
}

.windDirection {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  color: #2a3836 !important;
}

.hourlyForecast {
  margin-top: 16px;
}

.forecastTitle {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #555555 !important;
  margin-bottom: 12px !important;
}

.hourlyGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-top: 8px;
}

.hourlyItem {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 8px;
  text-align: center;
}

.hourTime {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 12px !important;
  color: #555555 !important;
  margin-bottom: 4px !important;
}

.hourTemp {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #034832 !important;
  margin: 4px 0 !important;
}

.precipInfo {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  background: rgba(3, 72, 50, 0.08);
  color: #034832;
  padding: 2px 6px;
  border-radius: 4px;
  margin-top: 4px;
  display: inline-block;
  font-size: 11px !important;
}

/* Events styles */
.eventsGrid {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.eventCard {
  background: #f8f9fa;
  border-radius: 6px;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.eventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eventHeaderLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.eventType {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 600;
  color: #034832;
  font-size: 13px;
}

.eventDuration {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  background: rgba(3, 72, 50, 0.08);
  color: #034832;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
}

.eventTag {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 11px !important;
  line-height: 1.2 !important;
  padding: 1px 4px !important;
  margin: 0 !important;
}

.eventTimeRange, .eventMetric {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 12px;
  color: #555555;
}

.eventIcon {
  color: #034832;
  flex-shrink: 0;
}

.showMoreButton {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  align-self: center !important;
  margin-top: 4px !important;
  color: #034832 !important;
  background: rgba(3, 72, 50, 0.08) !important;
  border: 1px solid rgba(3, 72, 50, 0.1) !important;
  font-size: 12px !important;
  height: 24px !important;
  padding: 0 8px !important;
}

.showMoreButton:hover {
  background: rgba(3, 72, 50, 0.12) !important;
}

.allEventsModal {
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.allEventsModal :global(.ant-modal-content) {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.emptyState {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 13px !important;
  color: #555555;
}

/* Modal header styles */
.modalHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.modalHeader h6 {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #2a3836 !important;
  margin: 0 !important;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .regionData {
    grid-template-columns: 1fr;
  }
}

/* Bulk Storm Strategy Page Styles */
.container {
    padding: 24px;
  }
  
  .regionSection {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
    margin-bottom: 24px;
  }
  
  .regionContent {
    background: white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 12px 40px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 12px -2px;
    padding: 20px;
    margin-bottom: 24px;
  }
  
  .regionData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 16px;
  }
  
  .loading, .error {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 24px;
    background: #f8f9fa;
    border-radius: 6px;
    margin-top: 16px;
  }
  
  .error {
    color: #ff4d4f;
    background: #fff2f0;
  }
  
  .testModeWarning {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  
  .confirmRegion {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 16px;
    margin-top: 16px;
  }
  
  .confirmSites {
    margin-top: 12px;
  }
  
  .sitesList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    padding: 8px;
    background: white;
    border-radius: 4px;
  }
  
  .confirmMessage {
    margin-top: 12px;
  }
  
  .messagePreview {
    margin-top: 8px;
    padding: 12px;
    background: white;
    border-radius: 4px;
    white-space: pre-wrap;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .regionData {
      grid-template-columns: 1fr;
    }
  }
  
  /* Loading animation */
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .loadingIcon {
    animation: pulse 2s infinite;
  }
  
  /* Submit button styles */
  .submitButton {
    min-width: 200px !important;
    background-color: #034832 !important;
    color: white !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 8px 24px !important;
    height: auto !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 15px !important;
    border: none !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12) !important;
  }
  
  .submitButton:hover {
    background-color: #023324 !important;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2) !important;
  }
  
  .submitButton:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(255, 255, 255, 0.7) !important;
    box-shadow: none !important;
  }
  
  /* Header styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .headerLeft {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .bulkButton {
    background-color: #034832 !important;
    color: white !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 8px 16px !important;
    height: auto !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 14px !important;
    border: none !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12) !important;
    transition: all 0.2s ease !important;
  }
  
  .bulkButton:hover {
    background-color: #023324 !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15) !important;
  }
  
  .backButton {
    color: #034832 !important;
    border-color: #034832 !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    text-transform: none !important;
    font-weight: 500 !important;
    padding: 8px 16px !important;
    height: auto !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 8px !important;
    font-size: 14px !important;
    transition: all 0.2s ease !important;
  }
  
  .backButton:hover {
    background-color: rgba(3, 72, 50, 0.04) !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1) !important;
  }
  
  /* Bulk page specific styles */
  .selectionCard {
    padding: 24px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .cardHeaderLeft {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .cardIcon {
    color: #034832;
  }
  
  .generateAllButton {
    background-color: #034832 !important;
    color: #fff !important;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 8px 16px !important;
    border: none !important;
    border-radius: 4px !important;
    transition: background-color 0.2s ease, transform 0.2s ease;
  }
  
  .generateAllButton:hover {
    background-color: #023324 !important;
    transform: translateY(-2px);
  }
  
  .generateAllButton:disabled {
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed !important;
  }
  
  .regionCardsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-top: 24px;
  }
  
  .regionCardGrid {
    border: 1px solid rgba(0, 0, 0, 0.06) !important;
    border-radius: 12px !important;
    transition: all 0.2s ease-in-out !important;
    background: white !important;
    height: 100% !important;
    min-height: 200px !important;
    position: relative !important;
  }
  
  .regionCardGrid.loading {
    overflow: hidden !important;
  }
  
  .regionCardGrid.loading::before {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(3, 72, 50, 0.05) 50%,
      rgba(255, 255, 255, 0) 100%
    ) !important;
    animation: shimmer 1.5s infinite !important;
  }
  
  .regionCardGrid.loading .regionCardContent {
    opacity: 0.7 !important;
  }
  
  .regionCardLoading {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 12px !important;
    z-index: 2 !important;
  }
  
  .regionCardLoading svg {
    color: #034832 !important;
    animation: spin 1s linear infinite !important;
  }
  
  .regionCardLoading p {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    color: #034832 !important;
    margin: 0 !important;
  }
  
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .regionCardContent {
    padding: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 12px !important;
    height: 100% !important;
  }
  
  .regionCardContent h6 {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #034832 !important;
    margin: 0 !important;
  }
  
  .regionCardStats {
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
  }
  
  .regionCardStats :global(.ant-tag) {
    margin: 0 !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 12px !important;
    padding: 2px 8px !important;
    border-radius: 4px !important;
    line-height: 18px !important;
  }
  
  .regionCardMessage {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    color: #555555 !important;
    margin: 0 !important;
    flex-grow: 1 !important;
    line-height: 1.5 !important;
  }
  
  .editButton {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    background-color: #034832 !important;
    color: white !important;
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 6px 16px !important;
    border-radius: 6px !important;
    border: none !important;
    margin-top: auto !important;
    width: 100% !important;
    transition: background-color 0.2s ease !important;
  }
  
  .editButton:hover {
    background-color: #023324 !important;
  }
  
  .regionModal {
    /* Additional modal styling if needed */
  }
  
  .regionModalContent {
    margin-top: 16px;
  }
  
  .modalCard {
    margin-bottom: 16px;
    border-radius: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 8px 32px -4px,
                rgba(0, 0, 0, 0.02) 0px 4px 8px -2px !important;
  }
  
  .sitesTable {
    margin-top: 16px;
  }
  
  .sitesTable :global(.ant-table-thead > tr > th) {
    background: #f8f9fa !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: 600 !important;
  }
  
  .actionCard {
    border-radius: 12px !important;
    background: #034832 !important;
    margin-top: 32px !important;
    padding: 24px !important;
    box-shadow: rgba(3, 72, 50, 0.15) 0px 8px 24px !important;
    transition: all 0.2s ease-in-out !important;
  }
  
  .actionCard:hover {
    transform: translateY(-2px) !important;
    box-shadow: rgba(3, 72, 50, 0.2) 0px 12px 32px !important;
  }
  
  .actionContent {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  
  .actionInfo h6 {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: white !important;
    margin: 0 0 4px 0 !important;
  }
  
  .actionInfo p {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.8) !important;
    margin: 0 !important;
  }
  
  .submitButton {
    background-color: white !important;
    color: #034832 !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    padding: 12px 32px !important;
    border-radius: 8px !important;
    border: none !important;
    display: inline-flex !important;
    align-items: center !important;
    gap: 8px !important;
    transition: all 0.2s ease !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px !important;
  }
  
  .submitButton:hover {
    background-color: #f8f9fa !important;
    transform: translateY(-1px) !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px !important;
  }
  
  .submitButton:disabled {
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: rgba(3, 72, 50, 0.5) !important;
    cursor: not-allowed !important;
    transform: none !important;
    box-shadow: none !important;
  }
  
  .submitButton svg {
    width: 20px !important;
    height: 20px !important;
  }
  
  .confirmRegions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 16px;
    margin-top: 16px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .regionData {
      grid-template-columns: 1fr;
    }
    .regionCardsGrid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  
    .backButton {
      width: 100%;
      justify-content: center;
    }
  }
  
  /* Weather styles in modal */
  .regionModal .hourlyGrid {
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
  }

  .regionModal .hourlyItem {
    padding: 6px;
    min-width: 0 !important;
    width: 100%;
  }

  .regionModal .hourTime {
    font-size: 11px !important;
    margin-bottom: 2px !important;
  }

  .regionModal .hourTemp {
    font-size: 14px !important;
    margin: 2px 0 !important;
  }

  .regionModal .precipInfo {
    font-size: 10px !important;
    padding: 1px 4px !important;
    margin-top: 2px !important;
    width: auto !important;
  }
  
  /* Modal footer button */
  .regionModal :global(.ant-modal-footer) {
    padding: 16px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }

  .regionModal :global(.ant-modal-footer button) {
    background-color: #034832 !important;
    color: white !important;
    border: none !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: 36px !important;
    padding: 0 24px !important;
    border-radius: 6px !important;
    transition: all 0.2s ease !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12) !important;
  }

  .regionModal :global(.ant-modal-footer button:hover) {
    background-color: #023324 !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15) !important;
  }

  .regionModal :global(.ant-modal-footer button:active) {
    transform: translateY(0) !important;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12) !important;
  }
  